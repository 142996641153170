import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'

import BlockBuilder from '../../components/builder/BlockBuilder'

import GenericSlider from '../../components/blocks/GenericSlider'

export default ({ data }) => {
  const { page } = data
  const { title, content, featuredImage, excerpt, databaseId, slug, blocks } = page

  return (
    <Layout
      bodyClass={`page-template-default page page-id-${databaseId} ${slug} ${title} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}
    >
      <Seo title={title} description={excerpt} />
      <section className="top-of-page-item top-page"></section>
      <article
        className={`post-${databaseId} post page type-page status-publish hentry`}
        id={`post-${databaseId}`} data-scroll="skew"
      >

        <div className="gutenberg-wrap">
          <BlockBuilder blocks={blocks} title={title} />
        </div>
      
      </article>
    </Layout>
  )
}

export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageContent
      blocks {
        originalContent

        ... on WpAcfResumePageBuilderBlock {
          dynamicContent
          originalContent
          acf {
            resume_page_builder {
              page_header_block {
                address
                email
                phone_number
              }
              page_footer_block {
                page_number
              }
              text_block
              proficiency_block {
                title
                sub_title
                image {
                  remoteFile {
                    publicURL
                  }
                }
              }
              tag_list_block {
                tag
                change_tag_color
              }
              experience_block {
                title
                date_range
                description
                list_block {
                  list_item
                }
              }
              cover_page_block {
                year
                color
              }
            }
          }
        }

        ... on WpAcfPageBannerBlock {
          dynamicContent
          originalContent
          acf {
            about_face {
              remoteFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            background_color
            page_title
          }
        }

        ... on WpAcfLayoutBuilderBlock {
          dynamicContent
          originalContent
          acf {
            row {
              columns {
                text_and_image
                text
                video
                image {
                  remoteFile {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }

        ... on WpAcfFullwidthImageBlock {
          dynamicContent
          originalContent
          acf {
            video
            image {
              remoteFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            caption
          }
        }

        ... on WpAcfImmersiveScrollBlock {
          dynamicContent
          originalContent
          acf {
            text_align
            sub_title
            solid_color
            paragraph
            gradient
            title
            video
            image {
              remoteFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }

        ... on WpAcfGenericSliderBlock {
          dynamicContent
          originalContent
          acf {
            slide {
              slide_settings {
                
                background_credits
                credit_link
                credit_text
                gradient_background
                image_background {
                  remoteFile {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                }
                image_or_video
                solid_color_background
                solid_or_gradient
                video_url
              }
            }
          }
        }

        ... on WpAcfIntroBlock {
          dynamicContent
          originalContent
          acf {
            sub_title
            title
          }
        }

        ... on WpAcfIconListBlock {
          dynamicContent
          originalContent
          acf {
            add_column {
              image {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              svg {
                localFile {
                  publicURL
                  extension
                  publicURL
                }
              }
              svg_or_image
              title
            }
          }
        }

        ... on WpAcfLargeTextBlock {
          dynamicContent
          originalContent
          acf {
            gradient_or_solid
            solid_text_color
            text
          }
        }

        ... on WpAcfTabsBlock {
          dynamicContent
          originalContent
          acf {
            tab {
              tab_content
              tab_title
              tab_title_icon {
                remoteFile {
                  childImageSharp {
                    fluid(maxWidth: 75, quality: 92) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }

        ... on WpAcfPortfolioHeroBannerBlock {
          dynamicContent
          originalContent
          acf {
            project_color
            project_secondary_color
            project_slides {
              add_background_credits
              background_credit_text
              image_or_video
              projectimage {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              projectvideo
            }
            project_subtitle
          }
        }

        ... on WpAcfPortfolioOverviewBlock {
          dynamicContent
          originalContent
          acf {
            created_at {
              target
              title
              url
            }
            project_link {
              target
              title
              url
            }
            services
            year_created
          }
        }

        ... on WpAcfMediumTextBlock {
          dynamicContent
          originalContent
          acf {
            content
          }
        }

        ... on WpAcfTwoImageOffsetBlock {
          dynamicContent
          originalContent
          acf {
            background_color
            first_image {
              image {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              caption
            }
            second_image {
              image {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
              caption
            }
          }
        }

        ... on WpAcfImageGridBlock {
          dynamicContent
          originalContent
          acf {
            grid_type
            images {
              image_or_video
              video
              image {
                remoteFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        
        ... on WpAcfCodeBlock {
          dynamicContent
          originalContent
          acf {
            code
          }
        }

        ... on WpAcfFooterContactBlock {
          dynamicContent
          originalContent
          acf {
            body_text
            email
            subject_message
          }
        }
        
        ... on WpAcfContactFormBlock {
          dynamicContent
          originalContent
          acf {
            email_address
          }
        }

        ... on WpAcfServiceIntroBlock {
          dynamicContent
          originalContent
          acf {
            background_title
            description
            main_title
            service_color
            service_icon {
              remoteFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
        
        ... on WpAcfServiceGridBlock {
          dynamicContent
          originalContent
          acf {
            service_color
            service_item {
              description
              icon
              lottie_slug
              title
            }
          }
        }

        ... on WpAcfPortfolioSliderBlock {
          dynamicContent
          originalContent
          acf {
            slide {
              portfolio_item {
                ACFAllPostFields {
                  mainColor
                  mainImage {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                  secondaryColor
                }
                ACFPortfolioFields {
                  fieldGroupName
                  gradientBackground
                  homeGridLayoutSelection
                 
                  portfolioItemSubHeader
                  portfolioLottieSvg
                 
                  portfolioPng {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                  portfolioSvg {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                }
                featuredImage {
                  node {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                    
                  }
                }
                slug
                title
              }
            }
          }
        }

        ... on WpAcfPortfolioGridBlock {
          dynamicContent
          originalContent
          acf {
            grid_item {
              portfolio_item {
                categories {
                  nodes {
                    slug
                    uri
                    name
                  }
                }
                ACFAllPostFields {
                  mainColor
                  mainImage {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                  secondaryColor
                }
                ACFPortfolioFields {
                  fieldGroupName
                  gradientBackground
                  homeGridLayoutSelection
                 
                  portfolioItemSubHeader
                  portfolioLottieSvg
                  portfolioPng {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                  portfolioSvg {
                    remoteFile {
                      childImageSharp {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                  
                }
                slug
                uri
                title
              }
            }
          }
        }



      }

    }
    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
